<template>
  <div class="l-auth">
    <div class="l-authHeader">
      <header class="p-authHeader">
        <div class="l-container">
          <h1 class="p-authHeader__logo">
            <app-link :to="{ name: 'Index', params: { portalId } }">
              <PSiteLogo />
            </app-link>
          </h1>
        </div>
      </header>
    </div>
    <div class="l-authMain">
      <router-view />
    </div>
    <div class="l-authFooter">
      <footer class="p-authFooter">
        <div class="l-container">
          <small class="p-authFooter__copyright">
            &copy; Hitachi, Ltd. All rights reserved.
          </small>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import PSiteLogo from '@/components/common/PSiteLogo.vue'

export default {
  name: 'AuthLayout',
  components: { PSiteLogo },
}
</script>
